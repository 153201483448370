import * as React from "react";
import classnames from "classnames";

import { TitleProps } from "../../interfaces/TitleProps";

const Title: React.FC<TitleProps> = ({ as, className, children, style }) => {
  const baseClassName = "font-black leading-tight font-title";
  switch (as) {
    case "h1":
      return (
        <h1
          className={classnames(
            "text-xxxl sm:text-xxxxl mb-s",
            baseClassName,
            className
          )}
          style={style}
        >
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2
          className={classnames(
            "text-xxl sm:text-xxxl mb-s",
            baseClassName,
            className
          )}
          style={style}
        >
          {children}
        </h2>
      );
    case "h3":
      return (
        <h3
          className={classnames(
            "text-xl sm:text-xxl mb-s",
            baseClassName,
            className
          )}
          style={style}
        >
          {children}
        </h3>
      );
    case "h4":
      return (
        <h4
          className={classnames(
            "text-l sm:text-xl mb-xs",
            baseClassName,
            className
          )}
          style={style}
        >
          {children}
        </h4>
      );
    case "h5":
      return (
        <h5
          className={classnames("text-l mb-xs", baseClassName, className)}
          style={style}
        >
          {children}
        </h5>
      );
    case "h6":
      return (
        <h6
          className={classnames("text-l mb-xs", baseClassName, className)}
          style={style}
        >
          {children}
        </h6>
      );
    default:
      return null;
  }
};

export default Title;
