import * as React from "react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { graphql, useStaticQuery } from "gatsby";

import Paragraph from "../components/atoms/paragraph";
import Title from "../components/atoms/title";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className='font-bold'>{text}</span>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    [BLOCKS.HEADING_1]: (node, children) => <Title as='h1'>{children}</Title>,
    [BLOCKS.HEADING_2]: (node, children) => <Title as='h2'>{children}</Title>,
    [BLOCKS.HEADING_3]: (node, children) => <Title as='h3'>{children}</Title>,
    [BLOCKS.HEADING_4]: (node, children) => <Title as='h4'>{children}</Title>,
    [BLOCKS.HEADING_5]: (node, children) => <Title as='h5'>{children}</Title>,
    [BLOCKS.HEADING_6]: (node, children) => <Title as='h6'>{children}</Title>,
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className='list-disc pl-m'>{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className='list-decimal pl-m'>{children}</ol>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const allAsset = useStaticQuery(
        graphql`
          query {
            allContentfulAsset {
              nodes {
                contentful_id
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
        `
      );
      const nodeImg = allAsset.allContentfulAsset.nodes.filter(
        (el) => el.contentful_id === node.data.target.sys.id
      )[0];
      const gatsbyNodeImg = getImage(nodeImg.localFile);
      return <GatsbyImage className='my-s' image={gatsbyNodeImg} alt={""} />;
    },
  },
};
export default options;
