import * as React from "react";
import classnames from "classnames";
import { Link } from "gatsby";

import LanguageSelector from "../molecules/languageSelector";
import Paragraph from "../atoms/paragraph";

export default function NavbarItems({
  items,
  className,
  languageSelector
}: {
  items: { link: string; label: string }[];
  className?: string;
  languageSelector?: boolean;
}) {
  return (
    <ul className={classnames("text-white flex items-center", className)}>
      {items.map((el, i) => {
        return (
          <li className='mx-m filter drop-shadow-lg hover:underline' key={i}>
            <Link to={el.link}>
              <Paragraph style={{ marginBottom: "0px" }}>{el.label}</Paragraph>
            </Link>
          </li>
        );
      })}
      {languageSelector && <LanguageSelector />}
    </ul>
  );
}
