import * as React from "react";
import classnames from "classnames";
import { FiArrowRight } from "react-icons/fi";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactHtmlParser, { processNodes } from "react-html-parser";
import slugify from "slugify";

import Title from "../atoms/title";
import Paragraph from "../atoms/paragraph";
import Button from "../atoms/button";
import options from "../../utils/htmlParser";
import { LanguageContext } from "../templates/homepage";

export default function ProductPreview({
  data,
  index,
}: {
  index: number;
  data: {
    productName: string;
    shortDescription: any;
    bottle?: any;
    draw?: any;
    primaryColor?: string;
    icon?: any;
    subtitle: string;
    characteristics?: string[];
    composition?: string[];
  };
}) {
  function isEven(n: number) {
    return n % 2 == 0;
  }
  const productImg = data.bottle && getImage(data.bottle.localFile);
  const productDraw = data.draw && getImage(data.draw.localFile);
  const productIcon = data.icon && getImage(data.icon.localFile);
  const actualLanguage = React.useContext(LanguageContext);
  return (
    <div
      className={classnames(
        "relative pl-m md:pl-l flex flex-col-reverse md:flex-row",
        !isEven(index) ? " bg-white" : " bg-beige"
      )}
    >
      <div className='flex-1 flex flex-col sm:flex-row flex-wrap relative'>
        {productDraw && (
          <div className='z-0 absolute top-0 right-m'>
            <GatsbyImage image={productDraw} alt={data.draw.title || ""} />
          </div>
        )}
        <div className='hidden sm:flex relative w-1/3  justify-center'>
          {productImg && (
            <div>
              <div className='hidden sm:block sticky my-m top-0 z-10'>
                <GatsbyImage image={productImg} alt={data.bottle.title || ""} />
              </div>
            </div>
          )}
          <div
            className={classnames(
              "absolute w-12 z-0 md:-mt-l",
              !isEven(index) ? " bg-beige" : " bg-white"
            )}
            style={{ height: "calc(100%  + 64px)" }}
          />
        </div>
        <div className='relative z-20 py-m px-xs sm:p-l sm:pl-0 w-full sm:w-2/3'>
          <Title style={{ maxWidth: 150 }} as='h3'>
            {data.productName}
          </Title>
          {data.composition && (
            <div className='mb-s'>
              {data.composition.map((compo, i) => {
                function transform(node, index) {
                  if (node.type === "tag" && node.name === "span") {
                    return (
                      <span style={{ color: data.primaryColor }} key={index}>
                        {processNodes(node.children, transform)}
                      </span>
                    );
                  }
                }
                return (
                  <Paragraph className='text-lg' key={i}>
                    {ReactHtmlParser(compo, {
                      decodeEntities: true,
                      transform,
                    })}
                  </Paragraph>
                );
              })}
            </div>
          )}
          {data.shortDescription &&
            renderRichText(data.shortDescription, options)}
          <Button
            className='mt-s'
            style={{ background: data.primaryColor, minWidth: 175 }}
            href={"/" + actualLanguage.slice(0, 2) + "/" + slugify(data.productName).toLowerCase()}
            icon={<FiArrowRight />}
          >
            Know More
          </Button>
        </div>
      </div>
      <div
        className='flex flex-row flex-wrap sm:flex-col justify-center text-light w-full md:w-72 lg:w-96 p-m'
        style={{ background: data.primaryColor }}
      >
        {productIcon && (
          <div className='order-2 sm:order-none	mr-s sm:mr-0'>
            <GatsbyImage
              className='mb-s'
              image={productIcon}
              alt={data.icon.title || ""}
            />
          </div>
        )}
        <Title className='uppercase mb-s order-1 sm:order-none' as='h4'>
          {data.subtitle}
        </Title>
        <div className='order-3 sm:order-none	flex-1'>
          {data.characteristics?.map((char, i) => {
            return (
              <div
                className={classnames("border-t border-light uppercase py-xs")}
                key={i}
              >
                <Paragraph style={{ marginBottom: 0 }}>{char}</Paragraph>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
