import * as React from "react";
import classnames from "classnames";
import { Link as GatsbyLink } from "gatsby";

import { ButtonProps } from "../../interfaces/ButtonProps";

const Button: React.FC<ButtonProps> = ({
  onclick,
  icon,
  href,
  className,
  target,
  children,
  style,
}) => {
  const isInternalLink: boolean | undefined = href?.startsWith("/");
  const baseClassName: string =
    "font-bold rounded-full group text-white text-sm px-m py-xs transition duration-500 transform hover:scale-105 hover:shadow inline-flex items-center";
  if (!href) {
    return (
      <button
        onClick={onclick}
        className={classnames(baseClassName, className)}
        style={style}
      >
        {children}
        {icon && (
          <div className='group-hover:animate-wiggle pl-s ml-auto'>{icon}</div>
        )}{" "}
      </button>
    );
  } else {
    if (isInternalLink) {
      return (
        <GatsbyLink
          onClick={onclick}
          to={href}
          className={classnames(baseClassName, className)}
          style={style}
        >
          {children}
          {icon && (
            <div className='group-hover:animate-wiggle pl-s ml-auto'>
              {icon}
            </div>
          )}
        </GatsbyLink>
      );
    } else {
      return (
        <a
          onClick={onclick}
          href={href}
          target={target}
          className={classnames(baseClassName, className)}
          style={style}
        >
          {children}
          {icon && (
            <div className='group-hover:animate-wiggle pl-s ml-auto'>
              {icon}
            </div>
          )}
        </a>
      );
    }
  }
};

export default Button;
