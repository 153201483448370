import * as React from "react";
import classnames from "classnames";
import { FiArrowRight } from "react-icons/fi";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Button from "../atoms/button";
import options from "../../utils/htmlParser";
import Title from "../atoms/title";
import Paragraph from "../atoms/paragraph";

const HeroAlt: React.FC<{
  data: {
    title: string;
    subtitle?: string;
    content?: any;
    bgColor?: string;
    price?: string;
    floatingImg?: any;
    cta?: { label: string; to: string };
  };
  className?: string;
}> = ({ data, className }) => {
  const floatingImg = data.floatingImg && getImage(data.floatingImg.localFile);
  return (
    <section
      id='hero'
      style={{
        background: data.bgColor || "#212121",
      }}
      className={classnames("flex flex-wrap items-center relative", className)}
    >
      <div className='z-10 relative container text-light'>
        <div className='flex flex-col-reverse sm:flex-row flex-wrap items-center pt-l sm:pt-xl pb-l'>
          {floatingImg && (
            <div className='sm:w-1/3 mt-s flex'>
              <GatsbyImage
                className='md:mx-auto'
                image={floatingImg}
                alt={data.floatingImg.title || ""}
              />
            </div>
          )}
          <div className='text-center sm:text-left sm:w-2/3 ml-0 filter drop-shadow-lg'>
            <Title className='max-w-lg' as='h1'>
              {data.title}
            </Title>
            {data.subtitle && <Title as='h2'>{data.subtitle}</Title>}
            {data.content && renderRichText(data.content, options)}
            {data.price && (
              <Paragraph className='my-m text-xxl font-bold'>
                {data.price}
              </Paragraph>
            )}
            {data.cta && (
              <Button
                className='mt-s bg-light text-dark'
                href={data.cta.to}
                icon={<FiArrowRight />}
              >
                {data.cta.label}
              </Button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroAlt;
