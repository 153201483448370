import React from "react";

export default function Reassurance({ data }) {
  return (
    <div className='container grid grid-cols-1 md:grid-cols-2 gap-y-m lg:grid-cols-3 text-center font-bold text-lg text-white mt-m mb-l'>
      {data.map((el, i) => (
        <div
          className='flex justify-center items-center block bg-cover bg-center h-full p-m mx-s rounded shadow-lg overflow-hidden relative'
          style={{ backgroundImage: `url(${el.image?.localFile.url})` }}
          key={i}
        >
          <span className='z-10 relative' style={{ maxWidth: 150 }}>
            {el.text}
          </span>
          <div className='z-0 absolute bg-dark opacity-50 w-full h-full top-0 left-0' />
        </div>
      ))}
    </div>
  );
}
