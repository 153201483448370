import * as React from "react";
import classnames from "classnames";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiLink,
} from "react-icons/fi";

export default function SocialLinks({
  items,
  className,
}: {
  items: string[];
  className?: string;
}) {
  if (!items) {
    return null;
  }
  return (
    <div className={classnames("", className)}>
      <div className='flex text-xl'>
        {items.map((el, i) => {
          if (el.includes("instagram")) {
            return (
              <a
                className='text-gray hover:text-primary mx-xs'
                href={el}
                key={i}
                target='_blank'
              >
                <FiInstagram />
              </a>
            );
          } else if (el.includes("facebook")) {
            return (
              <a
                className='text-gray hover:text-primary mx-xs'
                href={el}
                key={i}
                target='_blank'
              >
                <FiFacebook />
              </a>
            );
          } else if (el.includes("linkedin")) {
            return (
              <a
                className='text-gray hover:text-primary mx-xs'
                href={el}
                key={i}
                target='_blank'
              >
                <FiLinkedin />
              </a>
            );
          } else if (el.includes("twitter")) {
            return (
              <a
                className='text-gray hover:text-primary mx-xs'
                href={el}
                key={i}
                target='_blank'
              >
                <FiTwitter />
              </a>
            );
          } else {
            return (
              <a
                className='text-gray hover:text-primary mx-xs'
                href={el}
                key={i}
                target='_blank'
              >
                <FiLink />
              </a>
            );
          }
        })}
      </div>
    </div>
  );
}
