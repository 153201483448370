import * as React from "react";
import classnames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Title from "../atoms/title";
import Paragraph from "../atoms/paragraph";
import options from "../../utils/htmlParser";

const AboutUs: React.FC<{
  data: { title?: string; content?: any; sideImg?: any };
  className?: string;
}> = ({ data, className }) => {
  const aboutImg = getImage(data.sideImg.localFile);
  return (
    <section
      id='about-us'
      className={classnames(
        "pt-l md:pt-xl relative z-20 bg-gradient-to-b from-beige to-white",
        className
      )}
    >
      <div className='container'>
        <Title className='max-w-2xl uppercase lg:mb-l' as='h2'>
          {data.title}
        </Title>
        <div className='text-orange hidden md:block -ml-m w-m z-10 sticky top-0 float-left'>
          <div
            className='inline-block'
            style={{ textOrientation: "mixed", writingMode: "vertical-rl" }}
          >
            <Paragraph>#MORETHANFOOD</Paragraph>
          </div>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-m'>
          <div className='mb-s'>
            {data.content && renderRichText(data.content, options)}
          </div>
          <div className='lg:ml-l'>
            <GatsbyImage image={aboutImg} alt={data.sideImg.title || ""} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
