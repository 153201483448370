import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../layout";
import Hero from "../organisms/hero";
import Benefits from "../organisms/benefits";
import OurProducts from "../organisms/ourProducts";
import AboutUs from "../organisms/aboutUs";

import { StaticNavLinks } from "../../utils/staticLinks";

export const LanguageContext = React.createContext(null);

const HomePage = ({ data }) => {
  //redirect if browser language traduction is available
  const allLocales = data.allContentfulHomepage.nodes.map((element) =>
    element.locale.slice(0, 2)
  );
  const browserLanguageExists: boolean =
    typeof window !== "undefined" &&
    allLocales.some((element) => element === navigator.language);
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      if (
        window.location.pathname === "/" &&
        browserLanguageExists &&
        navigator.language !== "en"
      ) {
        window.location.replace(window.location.href + navigator.language);
      }
    }
  }, []);

  const { allProducts } = data.allContentfulProducts;
  const hpData = data.contentfulHomepage;
  const { locale } = hpData;
  const heroData = {
    content: hpData.heroContent,
    bgImg: hpData.backgroundImage,
    bgColor: hpData.backgroundColor,
    floatingImg: hpData.floatingImage,
    cta: { label: hpData.knowMoreButton, to: "#newsletter" },
  };
  const aboutData = {
    title: hpData.aboutUsTitle,
    content: hpData.aboutUsContent,
    sideImg: hpData.aboutUsImage,
  };
  const navLinks = data.menu.nodes[0]?.navLinks.navLinks;
  return (
    <LanguageContext.Provider value={locale}>
      <Layout
        seo={{
          metaTitle: hpData.metaTitle,
          metaDescription: hpData.metaDescription.metaDescription,
          metaKeywords: hpData.metaKeywords,
          lang: locale.slice(0, 2).toLowerCase(),
        }}
        navLinks={navLinks ? navLinks : StaticNavLinks}
        footerLinks={data.footerLinks}
        reassurance={data.reassurance}
      >
        <Hero data={heroData} />
        <Benefits data={hpData.benefits} />
        <OurProducts data={allProducts} />
        <AboutUs data={aboutData} />
      </Layout>
    </LanguageContext.Provider>
  );
};

export const query = graphql`
query HomepageQuery($id: String, $locale: String) {
  allContentfulHomepage {
    nodes {
      locale
    }
  }
  contentfulHomepage(id: { eq: $id }) {
    id
    locale
    metaTitle
    metaDescription {
      metaDescription
    }
    metaKeywords
    floatingImage {
      title
      localFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
    heroContent {
      raw
    }
    knowMoreButton
    backgroundColor
    aboutUsTitle
    aboutUsImage {
      title
      localFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
    aboutUsContent {
      raw
    }
    benefits {
      title
      localFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, height: 35)
        }
      }
    }
  }
  allContentfulProducts(filter: { locale: { eq: $locale } }) {
    allProducts: nodes {
      subtitle
      productName
      primaryColor
      composition
      shortDescription {
        raw
      }
      bottle {
        title
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 100)
          }
        }
      }
      icon {
        title
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, height: 75)
          }
        }
      }
      draw {
        title
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, height: 100)
          }
        }
      }
      characteristics
    }
  }
  menu: allContentfulMenu(filter: { locale: { eq: $locale } }) {
    nodes {
      navLinks {
        navLinks {
          label
          link
        }
      }
    }
  }
  footerLinks: allContentfulClassicPage(filter: {locale: {eq: $locale}}) {
    nodes {
      title
    }
  }
  reassurance: allContentfulReassurance(filter: {locale: {eq: $locale}}) {
    nodes {
      image {
        localFile {
          url
        }
      }
      text
    }
  }
}
`;

export default HomePage;
