import * as React from "react";
import classnames from "classnames";
import { FiMenu, FiXCircle } from "react-icons/fi";

import Logo from "../molecules/logo";
import NavbarItems from "../molecules/navbarItems";

const Navbar: React.FC<{  
  navLinks: {
    label: string; 
    link: string;
  }[]
}> = ({navLinks}) => {
  const [open, setOpen] = React.useState(false);

  const burgerBase = "absolute transition duration-750 transform";

  return (
    <div
      className={classnames(
        "px-s top-0 fixed lg:absolute w-full py-xxs z-40 bg-white py-s shadow-lg lg:shadow-none bg-white lg:bg-transparent"
      )}
    >
      <div className='mx-auto lg:max-w-screen-lg xl:max-w-screen-xl relative'>
        <div className='flex-1 flex justify-between items-center mx-auto'>
          <Logo dark className='block lg:hidden' />
          <Logo className='hidden lg:block' />
          <NavbarItems className='hidden lg:flex' items={navLinks} languageSelector/>
          <div
            className='w-6 h-6 ml-s text-lg cursor-pointer relative block lg:hidden'
            onClick={() => setOpen(!open)}
          >
            <FiMenu
              className={classnames(
                burgerBase,
                open ? "scale-0 rotate-180" : "scale-100 rotate-0"
              )}
            />
            <FiXCircle
              className={classnames(
                burgerBase,
                open ? "scale-100 rotate-0" : "scale-0 rotate-180"
              )}
            />
          </div>
        </div>
        {open && (
          <NavbarItems
            className='bg-white text-dark absolute flex lg:hidden flex-col -right-s top-m p-s shadow-xl'
            items={navLinks}
            languageSelector
          />
        )}
      </div>
    </div>
  );
};

export default Navbar;
