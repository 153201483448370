import * as React from "react";
import classnames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FiInfo, FiLink } from "react-icons/fi";
import { FaAmazon } from "react-icons/fa";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Title from "../atoms/title";
import options from "../../utils/htmlParser";
import Button from "../atoms/button";

const ProductInfos: React.FC<{
  data: {
    bottle?: { title: string; localFile: any };
    draw?: { title: string; localFile: any };
    informationUsefull?: any;
    longDescription?: any;
    primaryColor?: string;
    whereToBuy?: string;
    buttonLinks?: string[];
  };
  className?: string;
}> = ({ data, className }) => {
  const productImg = data.bottle && getImage(data.bottle.localFile);
  const drawImg = data.draw && getImage(data.draw.localFile);
  return (
    <section
      id='product-infos'
      className={classnames("bg-light flex justify-center", className)}
    >
      <div className='w-64 relative hidden md:flex justify-center mt-m'>
        {productImg && (
          <div>
            <div className='sticky py-s left-0 top-0 z-10'>
              <GatsbyImage image={productImg} alt={data.bottle.title || ""} />
            </div>
          </div>
        )}
        <div className={classnames("absolute w-12 z-0 bg-white h-full")} />
      </div>
      <div className='flex-1 ml-m pt-m pb-l max-w-4xl mr-m'>
        {drawImg && (
          <div className='table md:mx-auto mb-m'>
            <GatsbyImage image={drawImg} alt={data.draw.title || ""} />
          </div>
        )}
        {data.longDescription && renderRichText(data.longDescription, options)}
        {data.informationUsefull && (
          <div className='my-m'>
            <FiInfo className='float-left mr-xs' />
            {renderRichText(data.informationUsefull, options)}
          </div>
        )}
        {data.buttonLinks?.length > 0 && (
          <div id='order-now' className='my-m'>
            {data.whereToBuy && (
              <Title style={{ color: data.primaryColor }} as='h3'>
                {data.whereToBuy}
              </Title>
            )}
            <div className='flex flex-wrap'>
              {data.buttonLinks.map((el, i) => {
                const buttonIcon = el.includes("amazon") ? (
                  <FaAmazon />
                ) : (
                  <FiLink />
                );
                const shopName =
                  el.split(".")[1] + "." + el.split(".")[2].split("/")[0];
                return (
                  <Button
                    className='mb-s mr-s'
                    style={{ backgroundColor: data.primaryColor }}
                    key={i}
                    icon={buttonIcon}
                    href={el}
                    target='_blank'
                  >
                    Shop on{" "}
                    {shopName.charAt(0).toUpperCase() + shopName.slice(1)}
                  </Button>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ProductInfos;
