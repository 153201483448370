import * as React from "react";
import { PageProps, graphql } from "gatsby";

import Layout from "../layout";
import HeroAlt from "../organisms/heroAlt";
import Benefits from "../organisms/benefits";
import ProductIntro from "../organisms/productIntro";
import ProductInfos from "../organisms/productInfos";
import { StaticNavLinks } from "../../utils/staticLinks";

type DataProps = {
  contentfulProducts: {
    metaTitle?: string;
    metaDescription?: {
      metaDescription?: any;
    };
    metaKeywords?: string[];
    productName: string;
    subtitle?: string;
    informationUsefull: {
      raw: any;
    };
    introduction?: { introduction: string };
    price?: string;
    whereToBuy?: string;
    buttonLinks?: string[];
    backgroundColor?: string;
    primaryColor?: string;
    longDescription?: {
      raw: any;
    };
    shortDescription?: any;
    characteristics?: any;
    bottle?: any;
    draw?: any;
    icon?: any;
    locale?: string;
  };
  contentfulHomepage: any;
  menu: {
    nodes: {
      navLinks: {
        navLinks: {
          label: string; 
          link: string;
        }[]
      };
    } []
  };
  footerLinks: {
    nodes: {
      title: string;
    }[]
  };
  reassurance: {
    nodes: {
      image: {
        localFile: {
          url: string;
        }
      },
      text: string;
    }[]
  },
};

export const ProductLanguageContext = React.createContext(null);

const ProductPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const {
    metaTitle,
    metaDescription,
    metaKeywords,
    productName,
    subtitle,
    introduction,
    price,
    backgroundColor,
    primaryColor,
    shortDescription,
    whereToBuy,
    informationUsefull,
    longDescription,
    characteristics,
    bottle,
    buttonLinks,
    draw,
    icon,
    locale
  } = data.contentfulProducts;
  const hpData = data.contentfulHomepage;
  const heroData = {
    title: productName,
    subtitle: subtitle,
    content: shortDescription,
    price: price,
    bgColor: backgroundColor,
    floatingImg: bottle,
    cta: { label: whereToBuy, to: "#order-now" },
  };
  const introData = {
    icon,
    primaryColor,
    characteristics,
    introduction: introduction?.introduction,
  };
  const infosData = {
    primaryColor,
    draw,
    bottle,
    longDescription,
    whereToBuy,
    buttonLinks,
    informationUsefull,
  };
  const navLinks = data.menu.nodes[0]?.navLinks.navLinks;
  return (
    <ProductLanguageContext.Provider value={locale}>
      <Layout
        seo={{
          metaTitle: metaTitle,
          metaDescription: metaDescription?.metaDescription,
          metaKeywords: metaKeywords,
          lang: locale.slice(0, 2).toLowerCase(),
        }}
        navLinks={navLinks ? navLinks : StaticNavLinks}
        footerLinks={data.footerLinks}
        reassurance={data.reassurance}
      >
        <HeroAlt data={heroData} />
        <Benefits data={hpData.benefits} />
        <ProductIntro data={introData} />
        <ProductInfos data={infosData} />
      </Layout>
    </ProductLanguageContext.Provider>
  );
};

export const query = graphql`
  query ProductsQuery($id: String, $locale: String) {
    contentfulHomepage {
      benefits {
        title
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, height: 40)
          }
        }
      }
    }
    contentfulProducts(id: { eq: $id }) {
      productName
      subtitle
      whereToBuy
      buttonLinks
      introduction {
        introduction
      }
      longDescription {
        raw
      }
      price
      backgroundColor
      primaryColor
      shortDescription {
        raw
      }
      informationUsefull {
        raw
      }
      characteristics
      bottle {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 175)
          }
        }
      }
      icon {
        title
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      draw {
        title
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      metaKeywords
      locale
    }
    menu: allContentfulMenu(filter: {locale: {eq: $locale}}) {
      nodes {
        navLinks {
          navLinks {
            label
            link
          }
        }
      }
    }
    footerLinks: allContentfulClassicPage(filter: {locale: {eq: $locale}}) {
      nodes {
        title
      }
    }
    reassurance: allContentfulReassurance(filter: {locale: {eq: $locale}}) {
      nodes {
        image {
          localFile {
            url
          }
        }
        text
      }
    }
  }
`;

export default ProductPage;
