import * as React from "react";
import classnames from "classnames";
import { FiArrowRight } from "react-icons/fi";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Button from "../atoms/button";
import options from "../../utils/htmlParser";

const Hero: React.FC<{
  data: {
    content: any;
    bgImg: any;
    bgColor: string;
    floatingImg: any;
    cta: { label: string; to: string };
  };
  className?: string;
}> = ({ data, className }) => {
  const bgImg = getImage(data.bgImg?.localFile);
  const floatingImg = getImage(data.floatingImg.localFile);
  return (
    <section
      id='hero'
      style={{
        background: data.bgColor || "#F0EADE",
      }}
      className={classnames("flex flex-wrap items-center relative", className)}
    >
      <div className='pt-m md:pt-l z-10 relative container text-light'>
        <div
          className='flex flex-col sm:flex-row items-center text-center sm:text-left'
          style={{
            minHeight: "600px",
          }}
        >
          <div className='mt-s ml-0 md:ml-m filter drop-shadow-lg sm:flex-1'>
            {renderRichText(data.content, options)}
            {data.cta?.label && (
              <Button
                className='mt-s bg-light text-dark'
                href={data.cta.to}
                icon={<FiArrowRight />}
              >
                {data.cta.label}
              </Button>
            )}
          </div>
          {floatingImg && (
            <div
              style={{ maxWidth: "600px" }}
              className='mt-m md:mt-auto md:-mb-m'
            >
              <GatsbyImage
                className='w-48 xs:w-72 sm:w-auto	md:mx-m lg:mx-l'
                image={floatingImg}
                alt={data.floatingImg.title || ""}
              />
            </div>
          )}
        </div>
      </div>
      {bgImg && (
        <div className='absolute w-full h-full flex items-end z-0'>
          <GatsbyImage
            image={bgImg}
            alt={data.bgImg.title || ""}
            className='w-full'
          />
        </div>
      )}
    </section>
  );
};

export default Hero;
