import * as React from "react";
import classnames from "classnames";
import { FiArrowDown } from "react-icons/fi";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Motto from "../molecules/motto";

const Benefits: React.FC<{
  data: {
    title?: string;
    localFile: any;
  }[];
  className?: string;
}> = ({ data, className }) => {
  return (
    <section
      id='benefits'
      className={classnames("container relative z-10", className)}
    >
      <a
        href='#start'
        className='p-xxs group z-10 block absolute left-1/2 top-0 text-xxxxl bg-white rounded-full transform -translate-y-1/2 -translate-x-1/2 w-20 h-20 flex flex-wrap justify-center items-center text-dark'
      >
        <Motto className='w-full group-hover:w-0 transform transition scale-100 group-hover:scale-0' />
        <FiArrowDown className='w-0 group-hover:w-full transform transition scale-0 group-hover:scale-100' />
      </a>
      <div className='flex flex-wrap justify-between py-m'>
        {data.map((el, i) => {
          const icon = getImage(el.localFile);
          return (
            <div className='w-1/2 sm:w-auto text-center my-xs sm:my-0' key={i}>
              <GatsbyImage image={icon} alt={el.title || ""} />
            </div>
          );
        })}
      </div>
      <div id='start'></div>
    </section>
  );
};

export default Benefits;
