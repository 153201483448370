import React, { useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import ReactHtmlParser from "react-html-parser";
import { FiArrowRight } from "react-icons/fi";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import options from "../../utils/htmlParser";
import Paragraph from "../atoms/paragraph";
import Button from "../atoms/button";

export default function Mailchimp({ data }) {
  const [email, setEmail] = useState("");
  const [confirmation, setConfirmation] = useState(null);
  const [error, setError] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    addToMailchimp(email, {})
      .then((data) => {
        setConfirmation(data.msg);
      })
      .catch((err) => {
        setError(err.msg);
      });
  };
  return (
    <section id='newsletter' className='max-w-6xl mx-auto'>
      <div className='grid sm:grid-cols-2'>
        <div className='max-w-s pb-m px-m'>
          <form onSubmit={handleSubmit}>
            <div>
              {data.content && (
                <label htmlFor='email'>
                  {renderRichText(data.content, options)}
                </label>
              )}

              <div className='flex flex-wrap items-center'>
                <input
                  type='email'
                  value={email}
                  name='EMAIL'
                  className='border border-primary py-xxs px-xs rounded my-s'
                  onChange={(e) => setEmail(e.target.value)}
                  id='email'
                />
                <div className='ml-xs'>
                  <Button
                    type='submit'
                    id='mc-embedded-subscribe'
                    className='bg-primary'
                    icon={<FiArrowRight />}
                  >
                    Get My Discount
                  </Button>
                </div>
              </div>
            </div>
            {(confirmation || error) && (
              <>
                <Paragraph className='text-green'>
                  {confirmation &&
                    ReactHtmlParser(confirmation, {
                      decodeEntities: true,
                    })}
                </Paragraph>
                <Paragraph className='text-red'>
                  {error &&
                    ReactHtmlParser(error, {
                      decodeEntities: true,
                    })}
                </Paragraph>
              </>
            )}
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden='true'
            >
              <input
                type='text'
                name='b_19fd4fe5c89deb83f665b5b01_876b88b48e'
                tabIndex={-1}
                value=''
                readOnly
              />
            </div>
          </form>
        </div>
        <div
          className='bg-contain bg-center sm:bg-right-bottom bg-no-repeat'
          style={{
            backgroundImage: `url(${data.img.localFile.url})`,
            minHeight: 100,
          }}
        />
      </div>
    </section>
  );
}
