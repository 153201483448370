import * as React from "react";
import classnames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Title from "../atoms/title";
import Paragraph from "../atoms/paragraph";

const ProductIntro: React.FC<{
  data: {
    introduction?: string;
    icon?: any;
    characteristics?: string[];
    primaryColor: string;
  };
  className?: string;
}> = ({ data, className }) => {
  const iconImg = getImage(data.icon.localFile);
  return (
    <section
      id='product-intro'
      style={{ backgroundColor: data.primaryColor }}
      className={classnames("text-light", className)}
    >
      <div className='max-w-4xl mx-auto px-m py-l'>
        <div className='flex flex-col sm:flex-row'>
          <div className='w-60 mt-auto mr-s mb-xs'>
            <GatsbyImage image={iconImg} alt={data.icon.title || ""} />
          </div>
          <div>
            {data.characteristics?.length > 1 && (
              <div className='inline-flex uppercase  border-t border-b mb-s'>
                {data.characteristics.map((el, i) => {
                  return (
                    <Paragraph
                      className={classnames(
                        "py-xxs pr-s pl-xs",
                        i !== 0 && "border-l"
                      )}
                      style={{ marginBottom: 0 }}
                      key={i}
                    >
                      {el}
                    </Paragraph>
                  );
                })}
              </div>
            )}
            {data.introduction && (
              <Title style={{ marginBottom: 0 }} as='h4'>
                {data.introduction}
              </Title>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductIntro;
