import * as React from "react";
import { PageProps, graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Layout from "../layout";
import HeroAlt from "../organisms/heroAlt";
import options from "../../utils/htmlParser";
import { StaticNavLinks } from "../../utils/staticLinks";

type DataProps = {
  contentfulClassicPage: any;
  menu: {
    nodes: {
      navLinks: {
        navLinks: {
          label: string;
          link: string;
        }[];
      };
    }[];
  };
  footerLinks: {
    nodes: {
      title: string;
    }[];
  };
  reassurance: {
    nodes: {
      image: {
        localFile: {
          url: string;
        };
      };
      text: string;
    }[];
  };
};

export const classicPageLanguageContext = React.createContext(null);

const ClassicPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { contentfulClassicPage } = data;
  const heroData = {
    title: contentfulClassicPage.title,
    subtitle: contentfulClassicPage.subtitle,
  };
  const navLinks = data.menu.nodes[0]?.navLinks.navLinks;
  const scriptToLoad: string =
    contentfulClassicPage.htmlScript?.htmlScript.toString();
  return (
    <classicPageLanguageContext.Provider value={contentfulClassicPage.locale}>
      <Layout
        seo={{
          metaTitle: contentfulClassicPage.metaTitle,
          metaDescription:
            contentfulClassicPage.metaDescription?.metaDescription,
          metaKeywords: contentfulClassicPage.metaKeywords,
          lang: contentfulClassicPage.locale.slice(0, 2).toLowerCase(),
        }}
        navLinks={navLinks ? navLinks : StaticNavLinks}
        footerLinks={data.footerLinks}
        reassurance={data.reassurance}
      >
        <HeroAlt data={heroData} />
        <section className='mt-l container'>
          {contentfulClassicPage.content &&
            renderRichText(contentfulClassicPage.content, options)}
          <div
            dangerouslySetInnerHTML={{
              __html: scriptToLoad,
            }}
          />
        </section>
      </Layout>
    </classicPageLanguageContext.Provider>
  );
};

export const query = graphql`
  query ClassicQuery($id: String, $locale: String) {
    contentfulClassicPage(id: { eq: $id }) {
      title
      subtitle
      htmlScript {
        htmlScript
      }
      content {
        raw
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      metaKeywords
      locale
    }
    menu: allContentfulMenu(filter: { locale: { eq: $locale } }) {
      nodes {
        navLinks {
          navLinks {
            label
            link
          }
        }
      }
    }
    footerLinks: allContentfulClassicPage(filter: { locale: { eq: $locale } }) {
      nodes {
        title
      }
    }
    reassurance: allContentfulReassurance(filter: { locale: { eq: $locale } }) {
      nodes {
        image {
          localFile {
            url
          }
        }
        text
      }
    }
  }
`;

export default ClassicPage;
