import * as React from "react";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

import Footer from "./organisms/footer";
import Navbar from "./organisms/navbar";
import Mailchimp from "./molecules/mailchimp";
import Reassurance from "./organisms/reassurance";

const Layout: React.FC<{
  children: JSX.Element | JSX.Element[] | string | string[];
  navLinks: {
    label: string;
    link: string;
  }[];
  footerLinks: {
    nodes: {
      title: string;
    }[];
  };
  reassurance: {
    nodes: {
      image: {
        localFile: {
          url: string;
        };
      };
      text: string;
    }[];
  };
  seo?: {
    metaTitle?: string;
    metaDescription?: string;
    metaKeywords?: string[];
    lang: string;
  };
}> = ({ children, seo, navLinks, footerLinks, reassurance }) => {
  const data: any = useStaticQuery(
    graphql`
      {
        newsletter: contentfulHomepage {
          content: newsletter {
            raw
          }
          img: newsletterImage {
            title
            localFile {
              url
            }
          }
        }
        footer: contentfulHomepage {
          socialLinks
        }
        footerLinks: allContentfulClassicPage {
          nodes {
            title
          }
        }
      }
    `
  );
  const { newsletter } = data;
  const dataFooter = { ...data.footer, footerLinks: footerLinks, navLinks };
  return (
    <>
      {seo && (
        <Helmet defer={false}>
          <html lang={seo.lang} />
          <title>{seo.metaTitle || "Maelha — Food supplements"}</title>
          <meta
            name='description'
            content={
              seo.metaTitle ||
              "Food supplements based on extracts of herbs and plants and made with inovative and effective formulas."
            }
          />
          <meta
            name='keywords'
            content={seo.metaKeywords?.join(", ") || "Maelha, food supplements"}
          />
        </Helmet>
      )}
      <Navbar navLinks={navLinks} />
      <main className='lg:pt-0 pt-m pb-l'>{children}</main>
      <Reassurance data={reassurance.nodes} />
      <Mailchimp data={newsletter} />
      <Footer data={dataFooter} />
    </>
  );
};

export default Layout;
