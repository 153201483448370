import * as React from "react";
import classnames from "classnames";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

export default function Logo({
  className,
  dark,
}: {
  className?: string;
  dark?: boolean;
}) {
  return (
    <Link
      to='/'
      className={classnames(className, "flex items-center uppercase")}
    >
      {dark ? (
        <StaticImage
          alt='logo Maelha'
          placeholder='tracedSVG'
          src='../../images/logo.png'
          height={30}
          quality={75}
        />
      ) : (
        <StaticImage
          alt='logo Maelha'
          placeholder='tracedSVG'
          src='../../images/logo-white.png'
          height={50}
          quality={75}
        />
      )}
    </Link>
  );
}
