import * as React from "react";
import classnames from "classnames";

import { ParagraphProps } from "../../interfaces/ParagraphProps";

const Paragraph: React.FC<ParagraphProps> = ({
  className,
  children,
  style,
}) => {
  return (
    <p
      className={classnames("font-text leading-5 font-base mb-xs", className)}
      style={style}
    >
      {children}
    </p>
  );
};
export default Paragraph;
