import * as React from "react";
import classnames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";

import Paragraph from "../atoms/paragraph";
import { LanguageContext } from "../templates/homepage";
import { ProductLanguageContext } from "../templates/product";
import { classicPageLanguageContext } from "../templates/classic";

export default function LanguageSelector({
  className,
}: {
    className?: string;
}) {
    const data = useStaticQuery(
        graphql`
          {
            allContentfulHomepage {
              nodes {
                locale
                languageName
              }
            }
          }
        `
    );
    let language: string;
    const homeActualLanguage = React.useContext(LanguageContext);
    const productPageActualLanguage = React.useContext(ProductLanguageContext);
    const classicPageActualLanguage = React.useContext(classicPageLanguageContext);

    if (homeActualLanguage) {
      language = homeActualLanguage;
    } else if (productPageActualLanguage) {
      language = productPageActualLanguage;
    } else if (classicPageActualLanguage) {
      language = classicPageActualLanguage;
    } else {
      language = 'en-US'
    }

    const locales = data.allContentfulHomepage.nodes;
    return (
    <div className={classnames("cursor-pointer text-white flex items-center flex flex-col group", className)}>
        <Paragraph className="mx-m filter drop-shadow-lg group-hover:underline text-black lg:text-white" style={{ marginBottom: "0px" }}>{language.slice(0, 2).toUpperCase()}</Paragraph>
        <div className="group-hover:block text-black absolute hidden">
          <div className="bg-white h-10" style={{clipPath: "polygon(50% 90%, 45% 100%, 55% 100%)"}}/>
          <div className="bg-white p-4">
            {locales && locales.map((el, i) => {
                return (
                  <div className='mx-m filter drop-shadow-lg hover:underline' key={i}>
                      <Link to={`/${el.locale.toLowerCase() === "en-us" ? '' : el.locale.toLowerCase().slice(0, 2)}`}>
                      <Paragraph className={classnames(i === locales.length - 1 ? "mb-0" :  "mb-2.5", "w-max")}>{el.languageName}</Paragraph>
                      </Link>
                  </div>
                );
            })}
          </div>
        </div>
    </div>
  );
}

