import * as React from "react";
import classnames from "classnames";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

export default function Motto({ className }: { className?: string }) {
  return (
    <StaticImage
      alt='logo Maelha'
      placeholder='tracedSVG'
      src='../../images/motto.png'
      quality={75}
      className={className}
    />
  );
}
