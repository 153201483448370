import * as React from "react";
import classnames from "classnames";
import slugify from "slugify";

import Logo from "../molecules/logo";
import NavbarItems from "../molecules/navbarItems";
import SocialLinks from "../molecules/socialLinks";
import Paragraph from "../atoms/paragraph";

import { LanguageContext } from "../templates/homepage";
import { ProductLanguageContext } from "../templates/product";
import { classicPageLanguageContext } from "../templates/classic";

const Footer: React.FC<{
  data: {
    socialLinks: string[]; 
    footerLinks: { nodes: any };
    navLinks: {
      label: string; 
      link: string;
    }[];
  };
}> = ({ data }) => {
  let autoAddLinks = [];
  let language: string;
  const homeActualLanguage = React.useContext(LanguageContext);
  const productPageActualLanguage = React.useContext(ProductLanguageContext);
  const classicPageActualLanguage = React.useContext(classicPageLanguageContext);

  if (homeActualLanguage) {
    language = homeActualLanguage;
  } else if (productPageActualLanguage) {
    language = productPageActualLanguage;
  } else if (classicPageActualLanguage) {
    language = classicPageActualLanguage;
  } else {
    language = 'en-US'
  }

  data.footerLinks.nodes.map((el) =>
    autoAddLinks.push({
      label: el.title,
      link: `${language === 'en-US' ? '/en/' : ''}${slugify(el.title).toLowerCase()}`,
    })
  );
  const navLinks = [
    ...data.navLinks,
    ...autoAddLinks,
  ];
  return (
    <footer className={classnames("relative bg-dark text-light")}>
      <div className='container'>
        <div className='flex flex-wrap justify-center md:justify-between items-center py-m'>
          <Logo className='mb-s md:w-1/6' />
          <NavbarItems
            className='text-center md:w-2/3 flex-wrap flex justify-center'
            items={navLinks}
          />
          <SocialLinks
            className='mt-s md:w-1/6 flex justify-end'
            items={data.socialLinks}
          />
        </div>
        <div className='border-b block w-full border-light'></div>
        <div className='block text-center text-sm py-s'>
          {" "}
          <Paragraph className='block'>
            © {new Date().getFullYear()} MAELHA NUTRITION
          </Paragraph>
          <Paragraph>
            Maelha V.O.F - NL861254326B01 Schieweg 182 C 02, 3038BK Rotterdam -
            The Netherlands
          </Paragraph>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
