import * as React from "react";
import classnames from "classnames";

import Title from "../atoms/title";
import ProductPreview from "../molecules/productPreview";

const OurProducts: React.FC<{
  data: {
    productName: string;
    shortDescription: any;
    bottle?: any;
    draw?: any;
    primaryColor?: string;
    icon?: any;
    subtitle: string;
    characteristics?: string[];
    composition?: string[];
  }[];
  className?: string;
}> = ({ data, className }) => {
  return (
    <section
      id='our-products'
      className={classnames("relative bg-beige", className)}
    >
      <div
        className='whitespace-nowrap z-10 sticky top-m float-left pt-m w-0 left-m md:left-l'
        style={{
          textOrientation: "mixed",
          writingMode: "vertical-rl",
        }}
      >
        <Title className='text-dark' as='h2'>
          Our products
        </Title>
      </div>
      {data.map((el, i) => {
        return <ProductPreview key={i} index={i} data={el} />;
      })}
    </section>
  );
};

export default OurProducts;
